.line-item-detail-group {
	&, &:last-child {
		margin-bottom: 0;
	}

	li {
		@apply tw-mb-1;
	}
}

.location-PRINT_PRICING {
	@apply tw-hidden;
}

@media print {
	.location-INFO_COLUMN {
		@apply tw-hidden;
	}

	.location-PRINT_PRICING {
		@apply tw-block;

		.line-item-detail-group {
			li {
				@apply tw-mb-0;
			}
		}
		strong {
			@apply tw-font-normal;
		}
	}
}
