.summaryWrapper {
	@apply tw-sticky tw-self-start;
	top: calc(var(--devBeam-height) + var(--engage-toolbar-height) - 1rem);

	button {
		@apply tw-w-full;
	}
}

.costSummary {
	@apply tw-px-4 tw-py-4 tw-space-y-7 tw-top-14 tw-border tw-border-gray-400;

	&.canceled {
		@apply tw-pb-8;
	}
}

.headerContainer {
	@apply tw-border-gray-400 tw-border-b;
}

.summaryGrid {
	@apply tw-grid tw-w-full tw-gap-y-3;

	grid-template-columns: 2.5fr 1.5fr;
	margin-top: 10px !important;
}
