.OrderSummaryCharges {
	@apply tw-w-full;
	th {
		@apply tw-font-normal;
	}
	th, td {
		@apply tw-pb-4;
	}
}
.OrderSummaryCharges-taxTooltip {
	@apply tw-left-0;
	bottom: 22px;
	right: -50%;
}
.retailDeliveryFeeTooltip {
	bottom: 25px;
	right: -135px;
}
.retailDeliveryFeeTooltipCaret {
	margin-left: 146.5px;
}
.OrderSummaryCharges-taxTooltipCaret {
	margin-left: 125px;
}
@screen md {
	.OrderSummaryCharges-taxTooltip {
		width: 305px;
	}
	.OrderSummaryCharges-taxTooltipCaret {
		margin-left: 116px;
	}
	.retailDeliveryFeeTooltip {
		right: -140px;
	}
	.retailDeliveryFeeTooltipCaret {
		margin-left: 142.5px;
	}
}
