@use 'sass:map';

@import 'quote-print-settings';

.footer {
	@apply tw-grid tw-gap-x-3 tw-grid-flow-col tw-justify-start tw-px-2 tw-py-4;

	@media print {
		@apply tw-hidden;
	}

	& > *:not(:last-child) {
		&:after {
			content: "|";
			align-self: center;
			margin-left: 12px;
		}
	}
}
