.list-option {
	@apply tw-mb-3;

	.highlight-styling {
		@apply tw-border-gray-100/60 tw-border-b-gray-100;
	}

	&:hover, &.selected-option {
		.list-label {
			@extend .highlight-styling;
		}
	}


	&.selected-option {
		.list-label {
			border-bottom-width: 2px;
		}
	}
}
.list-label {
	@apply tw-grid tw-items-center tw-gap-4 tw-border-solid tw-border tw-border-gray-400/60;

	grid-template-columns: 60px auto 48px;
	height: 70px;
	padding: 8px;

	div {
		@apply tw-justify-center;
	}
}

.list-image {
	@apply tw-text-center;

	.heart-icon {
		width: 24px;
	}
}

.item {
	@apply tw-grid tw-gap-4 tw-grid-cols-2 tw-content-start tw-no-underline tw-text-gray-100;
	grid-template-columns: 113px auto;
	margin-bottom: 12px;

	@screen md {
		margin-bottom: 15px;
	}
}

.col-2 {
	@apply tw-self-center;
	line-height: 21px;
}

.image-holder {
	@apply tw-flex tw-justify-center tw-items-center tw-overflow-hidden;
	height: 100px;

	@screen md {
		height: 110px;
	}

	img {
		object-fit: contain;
		height: 100%;
		max-height: 99%;
		max-width: 99%;
	}
}

.name {
	@apply tw-font-semibold;
	margin-bottom: 2px;
}

.list-name {
	@apply tw-break-words tw-line-clamp-1;

	&::first-letter {
		@apply tw-capitalize;
	}
}

.buttons {
	@apply tw-mt-7;
}

.list-of-lists {
	@apply tw-mb-6
}
