@use 'sass:map';

@import 'quote-print-settings';

.costSummary {
	@apply tw-pl-4 tw-pr-0 tw-py-4 tw-pb-16 tw-space-y-7 tw-col-span-4 tw-sticky tw-top-24 tw-self-start;
	top: calc(var(--devBeam-height) + var(--engage-toolbar-height));

	&.canceled {
		@apply tw-pb-8;
	}

	@media print {
		@apply tw-col-span-1 tw-px-0 tw-relative tw-top-0 tw-pb-0 tw-mb-0 tw-break-inside-avoid;

		@each $printType, $config in $imagePrintVisibility {
			&.printType-#{$printType} {
				@if map-get($config, "price") == false {
					.summaryGrid {
						@apply tw-hidden;
					}

					.headerContainer {
						@apply tw-border-b-0;
					}

					.expirationWarning {
						@apply tw-mt-0;
					}
				}
				@if map-get($config, "showExpiration") == false {
					.expirationWarning {
						@apply tw-hidden;
					}
				}
			}
		}
	}
}

.headerContainer {
	@apply tw-border-gray-400 tw-border-b;
}

.summaryGrid {
	@apply tw-grid tw-w-full tw-gap-y-3;

	grid-template-columns: 2.5fr 1.5fr;
	margin-top: 10px !important;

	@media print {
		@apply tw-pb-4;
		border-bottom: solid 1px theme('colors.gray.400');
	}
}


.expirationWarning {
	@apply tw-bg-gold/30 tw-relative;

	padding: 18px 15px 16px 46px;
	print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}

.expirationIcon {
	@apply tw-absolute;

	left: 16px;
	width: 22px;
	height: 20px;
}
