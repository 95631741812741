@use 'sass:map';

@import 'quote-print-settings';

.line-item {
	@apply tw-relative tw-grid tw-gap-4;

	padding-bottom: 70px;
	padding-left: 7px;
	padding-right: 7px;

	&:last-child {
		border-bottom: none;
	}

	.printQuantity, .printUnitPrice {
		@apply tw-hidden;
	}

	.lineItemMessaging {
		@apply tw-pt-3;
	}

	.availabilityMessages {
		@apply tw-font-bold;
	}

	.compactPrintMessaging {
		@apply tw-hidden tw-col-span-full;
	}

	@screen md {
		@apply tw-pb-10 tw-px-3;
		grid-template-columns: 4fr 8fr;
	}

	@media print {
		@apply tw-gap-3;
		grid-template-columns: 2fr 4fr .5fr;

		&.printType-COMPACT_VIEW, &.printType-PROFORMA_INVOICE {
			@apply tw-pb-2;

			grid-template-columns: 3fr 1fr 1fr 1fr;

			.printQuantity, .printUnitPrice {
				@apply tw-block;
			}

			.printSubtotal {
				@apply tw-font-semibold;
			}
		}

		.line-item-title {
			&, a, button {
				@apply tw-font-normal;
			}
		}

		&.printType-NO_PRICING {
			grid-template-columns: 1.75fr 4fr;
		}

		@each $status, $visible in $statusPrintVisibility {
			@if $visible == false {
				&.status-#{$status} {
					@apply tw-hidden;
				}
			}
		}

		@each $printType, $config in $imagePrintVisibility {
			&.printType-#{$printType} {
				@if map-get($config, "image") == false {
					.lineItemImageContainer {
						@apply tw-hidden;
					}
				}

				@if map-get($config, "price") == false {
					.printSubtotal, .printUnitPrice {
						@apply tw-hidden;
					}
				}

				@if map-get($config, "availabilityBold") == false {
					.availabilityMessages {
						@apply tw-font-normal;
					}
				}

				@if map-get($config, "fullWidthLineItemMessaging") == true {
					.compactPrintMessaging {
						@apply tw-block;
					}

					.lineItemMessaging {
						@apply tw-hidden;
					}
				}

				@if map-get($config, "productNameBold") == true {
					.line-item-title {
						&, a, button {
							@apply tw-font-semibold;
						}
					}
				}
			}
		}
	}
}

.lineItemImageContainer {
	@apply tw-text-center;

	@media print {
		@apply tw-text-left;

		picture, img {
			max-width: 140px;
			max-height: 140px;
		}
	}

}

.printSubtotal, .printQuantity, .printUnitPrice {
	@apply tw-hidden;

	@media print {
		@apply tw-block tw-text-right;
	}
}

.line-item-info-column {
	@apply tw-grid tw-gap-3 tw-content-start;

	@media print {
		@apply tw-gap-2;
	}
}

.line-item-title {
	&, & a, & button {
		@apply tw-font-semibold tw-relative tw-text-gray-100 tw-no-underline tw-text-left;

		font-size: 17px;
	}

	a {
		&:hover, &:focus {
			@apply tw-underline;
		}
	}

	@screen md {
		&, & a, & button {
			@apply tw-text-base tw-pr-10;
		}
	}
}
