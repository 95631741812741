.pill {
	@apply tw-bg-gray-100 tw-text-white tw-rounded-full tw-px-3 tw-ml-2;

	&.DARK {
		@apply tw-bg-gray-100 tw-text-white;
	}

	&.LIGHT {
		@apply tw-bg-gray-500 tw-text-gray-100;
	}
}
