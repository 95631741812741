.signInError {
	@apply tw-text-red;
	border: 1px solid theme('colors.red.DEFAULT');
	width: 90%;
	padding: 15px;
	margin-bottom: 20px;

	> p:last-child {
		margin-bottom: 0;
	}
	> p:first-child {
		margin-top: 0;
	}
}
a.validation {
	@apply tw-block tw-text-red tw-no-underline;
	margin: 0 0 12px 0;
}
