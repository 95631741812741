.email-mms-modal {
	@apply tw-flex tw-flex-col;

	:global(.email-field-group) {
		@apply tw-mb-2;
		input {
			@apply md:tw-w-[250px];
		}
	}

	.heading-container {
		@apply tw-bg-navy;
		padding: 25px 80px 15px 30px;
	}
}