@mixin Body {
	@apply tw-font-normal;
}

@mixin Body--1 {
	@apply tw-font-light tw-text-lg;
	@screen md {
		@apply tw-text-lg tw-text-xl;
	}
}

@mixin Body--2 {
	@apply tw-text-base;
	@screen md {
		@apply tw-font-light tw-text-lg;
	}
}

.Body {
	@include Body;
	&.Body--1 {
		@include Body--1;
	}
	&.Body--2 {
		@include Body--2;
	}
}

a.Body {
	color: var(--anchor-color);
}
