/// Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.
///
/// @param {Number} $triangle-size - Width of the triangle.
/// @param {Color} $triangle-color - Color of the triangle.
/// @param {Keyword} $triangle-direction - Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
@mixin triangle(
	$triangle-size,
	$triangle-color,
	$triangle-direction
) {
	display: block;
	width: 0;
	height: 0;

	border: inset $triangle-size;

	content: '';

	@if ($triangle-direction == down) {
		border-bottom-width: 0;
		border-top-style: solid;
		border-color: $triangle-color transparent transparent;
	}
	@if ($triangle-direction == up) {
		border-top-width: 0;
		border-bottom-style: solid;
		border-color: transparent transparent $triangle-color;
	}
	@if ($triangle-direction == right) {
		border-right-width: 0;
		border-left-style: solid;
		border-color: transparent transparent transparent $triangle-color;
	}
	@if ($triangle-direction == left) {
		border-left-width: 0;
		border-right-style: solid;
		border-color: transparent $triangle-color transparent transparent;
	}
}