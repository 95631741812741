@media screen {
		.swatchOrdering {
			:global .MagicModal{
				@apply tw-p-0;
			}
			:global .MagicModal-header {
				@apply tw-absolute;
				right: 17px;
				top: 17px;
			}
		}
	}
