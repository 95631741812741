@import 'css/lib/tailwind-custom/components/Body.scss';

@media screen {
	$closeBtnSize: 32px;

	.swatch-viewer-modal {
		transition: min-height .25s;;
		position: relative;
		min-height: 400px;
		:global .fade-enter-done {
			position: relative;
		}
	}
	.swatch-viewer-modal > * {
		position: absolute;
		top: 0px;
	}

	.swatch-detail-modal-container {
		@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-between;
		min-height: 625px;

		.swatch-detail-closeBtnWrapper {
			@apply tw-absolute tw-top-0 tw-right-0 tw-shrink-0;
			height: 22px;
			width: 22px;
			margin: 5px 0 0 $closeBtnSize;
		}

		.swatch-detail-closeBtn {
			@apply tw-w-full tw-h-full tw-cursor-pointer;

			&::before,
			&::after {
				@apply tw-bg-gray-300 tw-absolute tw-top-0 tw-left-0;
				content: '';
			}

			&::before {
				height: $closeBtnSize;
				transform: rotate(45deg) translate(4px, -11px);
				width: 1px;
			}

			&::after {
				height: 1px;
				transform: rotate(45deg) translate(4px, 11px);
				width: $closeBtnSize;
			}

			&.swatch-detail-closeBtn:focus {
				@apply tw-shadow-none;
				outline: 1px dotted theme('colors.gray.300');
				outline-offset: 8px;
			}
		}

		.selected-swatch-name {
			margin-right: $closeBtnSize;
		}

		.details-toggle {
			@apply tw-pt-8 tw-pb-5 tw-pl-5 tw-text-sm;
			outline-offset: -1px;
			text-decoration: none !important;

			&:hover, &:focus {
				color: theme('colors.blue.DEFAULT');
				text-decoration: underline !important;
			}

			& + li {
				margin-top: 0;
			}

			&:before {
				border-left: 8px solid theme('colors.blue.DEFAULT');
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				content: '';
				height: 0;
				left: 2px;
				position: absolute;
				transition: transform .2s ease;
				width: 0;
			}

			&.details-expanded:before {
				transform: rotate(90deg);
			}
		}

		.swatch-detail-list {
			&.details-expanded {
				overflow: hidden;
				max-height: 2000px;
				animation: swatchDetailsSlide 750ms ease-in;
			}

			li:first-child {
				margin-top: 0;
			}
		}

		.swatch-detail-link-list {
			@apply tw-border-t tw-border-solid tw-border-gray-400;
		}

		.swatch-detail-images {
			flex: 0 0 400px;
			margin: 0 15px 15px 0;
		}

		.swatch-detail-descriptions {
			width: 100%;

			.selected-swatch-name {
				margin-bottom: 15px;
			}
		}

		.swatch-detail-link {
			margin-top: 10px;

			&:first-of-type {
				margin-top: 15px;
			}
		}

		.selected-swatch-image {
			width: 400px;
			height: 400px;
		}

		:global .swatch-description-paragraph {
			@include Body;
			@include Body--2;
			line-height: 1.4;
    		margin: 10px 0;
		}

		:global .group-header {
			margin-bottom: 8px;
		}

		.swatch-detail-buttons {
			margin-bottom: 25px;
		}

		:global .swatch-groups-swatches {
			@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-start;

			:global .group-swatch {
				&:global.swatch-button {
					@apply tw-border tw-border-solid tw-border-white;
					border-bottom-width: 3px;
					padding: 3px;
					margin: 5px;

					&:global.selected {
						@apply tw-border-gray-300;
						border-bottom-color: theme('colors.gray.DEFAULT');
					}
				}
			}

			:global .swatch-button {
				outline-offset: 2px;
				&:focus-within, &:hover {
					outline: 1px dotted theme('colors.gray.300');
				}

				cursor: pointer;
			}

			:global .swatch-button-image {
				max-width: 38px;
				max-height: 38px;
			}
		}

		:global .promo-item {
			display: inline-flex;
			border: solid 1px theme('colors.gray.300');
			padding: 10px;
			margin: 25px 0 0;

			:global .link {
				color: theme('colors.blue.DEFAULT');
				text-decoration: underline;
			}
		}
	}
	@media screen and (max-width: 900px) {
		.swatch-detail-modal-container {
			@apply tw-flex tw-flex-wrap tw-flex-col;

			.swatch-detail-images {
				flex: 1 1 400px;
				margin-right: 0;
			}

			.selected-swatch-image {
				width: auto;
				height: auto;
			}

			:global .details-toggle {
				font-size: 16px;
			}
		}
	}
}

@keyframes swatchDetailsSlide {
	0% {
		max-height: 0;
		overflow: hidden;
		padding: 0;
	}
	50% {
		padding: 0;
	}
	100% {
		max-height: 2000px;
	}
}