@import 'css/lib/tailwind-custom/triangle';

@media screen {
	.header-mobile-container {
		@apply tw-flex tw-flex-col tw-w-full tw-z-[110];
		&.header-mobile-container-shadow {
			@apply tw-relative;

			&::after {
				@apply tw-absolute tw-content-[''] tw-h-[9px] tw-opacity-50 tw-top-full tw-w-full tw-z-20;
				background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 50%);
			}
		}

		&.header-mobile-container--stuck {
			@apply tw-fixed tw-top-0 tw-left-0;
		}
	}

	.header-mobile, .top-menu-mobile {
		@apply tw-bg-white tw-flex tw-flex-nowrap tw-items-end tw-justify-between tw-relative tw-z-20;
		padding: 10px;

		.header-mobile-menu-button {
			flex: 0 0 25px;
			height: 20px;
			line-height: 0;
			position: relative;

			.icon-burger {
				position: relative;

				.burger-patty {
					@apply tw-left-0 tw-absolute tw-right-0 tw-transition-transform tw-duration-200 tw-ease-in-out;
					background: theme('colors.gray.DEFAULT');
					height: 1px;
					margin: -2px auto;
					transform: translateY(1px);

					&:first-child {
						transform: translateY(-8px);
					}

					&:last-child {
						transform: translateY(10px);
					}
				}
			}

			&.header-mobile-menu-button-open {
				.icon-burger .burger-patty {
					transform: scale(0);
					width: 25px;

					&:first-child {
						transform: rotate(45deg);
					}

					&:last-child {
						transform: rotate(-45deg);
					}
				}
			}
		}

		.header-mobile-search {
			line-height: 0;
		}

		.header-mobile-search-button {
			position: relative;

			.no-fill {
				fill: none;
			}
		}

		.header-mobile-search-form-container {
			@apply tw-bg-gray-600 tw-absolute tw-left-0 tw-w-full;
			padding: 10px 35px 10px 10px;
			top: 100%;

			&.header-mobile-search-form-container-shadow {
				&::after {
					@apply tw-block tw-left-0 tw-opacity-50 tw-absolute tw-w-full;
					background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 50%);
					content: ' ';
					height: 9px;
					top: 100%;
					z-index: 1;
				}
			}

			.header-mobile-search-form-field {
				@apply tw-flex;
			}

			.header-mobile-search-input {
				@apply tw-grow tw-border tw-border-solid tw-border-gray-300;
				height: 35px !important;
			}

			.header-mobile-search-submit {
				@apply tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-text-orange;
				padding: 0 15px;
				transform: translateX(-1px);
			}

			:global .rfk-sb .rfk_results {
				display: none
			}

			:global .rfk-sb .rfk_leftpane {
				width: 100%
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title {
				margin: 0
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title > div {
				display: none
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title > ul > li {
				margin: 0;
				padding: 10px;
				padding-left: 10px
			}

			:global .rfk-sb .rfk_left_pane {
				width: 100%;
				height: auto;
				padding-left: 0;
				padding-top: 0;
				background: #fff
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title > ul > li > a {
				@apply tw-p-0 tw-border-0 tw-leading-none tw-w-full;
				@apply tw-no-underline tw-text-gray #{'!important'};
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title > ul > li > a > span {
				@apply tw-w-full tw-block tw-capitalize tw-text-sm;
				@apply tw-text-gray #{'!important'};
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title > ul > li.rfk_highlight > a > span {
				color: inherit !important;
				border: 0
			}

			:global .rfk-sb .rfk_suggestions > ul > li.rfk_title > ul > li.rfk_highlight > a > span {
				text-decoration: none
			}

			:global .rfk-sb .rfk_close {
				display: block
			}

			:global .rfk-sb.rfk_visible {
				width: calc(100% - 55px);
				left: 6px !important;
				top: auto !important
			}

		}

		.header-mobile-logo-container {
			flex: 0 0 160px;
		}

		.header-mobile-account {
			line-height: 0;

			&.has-nudge {
				&:before {
					@apply tw-absolute tw-bg-gray-600 tw-border-l tw-border-t tw-border-gray-400 tw-content-[''] tw-h-[16px] tw-rotate-45 tw-translate-x-[2px] tw-top-[calc(100%+2px)] tw-w-[16px] tw-z-[1];
				}
			}
		}

		.header-mobile-account-button {
			position: relative;
		}

		.header-mobile-account-link {
			display: block;
			position: relative;
		}

		.header-mobile-account-menu {
			@apply tw-bg-white tw-absolute tw-right-0 tw-top-full;
			padding: 25px 15px 20px 10px;
		}

		.header-mobile-account-menu-item-container {
			margin: 0 0 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.header-mobile-account-menu-item {
			@apply tw-text-gray tw-font-bold tw-no-underline;
			font-size: 17px;
		}

		svg {
			height: 20px;
		}

		:global(.hover-fill) {
			fill: theme('colors.white.DEFAULT');
		}

		:global(.has-menu) {
			&:global(.has-focus) {
				&::after {
					width: 40px;
				}

				&::before {
					opacity: 1;
				}

				:global(.hover-fill) {
					fill: theme('colors.gray.DEFAULT');
				}
			}

			&::after {
				@apply tw-h-0 tw-absolute tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
				content: '';
				transition: width 0.2s ease-in-out;
				left: calc(50% - 20px);
				bottom: -13px;
				z-index: 158;
				border-top: 3px solid theme('colors.gray.DEFAULT');
			}

			&::before {
				@apply tw-transition-opacity tw-duration-200 tw-ease-in-out tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-opacity-0;
				content: '';
				@include triangle(8px, theme('colors.gray.DEFAULT'), down);
				bottom: -18px;
				z-index: 158;
			}
		}
	}

	.header-mobile-overlay {
		@apply tw-bg-gray tw-opacity-75 tw-absolute tw-w-full tw-z-[9];
		height: calc(100vh - (calc(100% - 9px)));
		top: calc(100% - 9px);
	}
}
