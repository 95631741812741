.line-item {
	@apply tw-relative tw-grid tw-gap-4;

	padding-bottom: 70px;
	padding-left: 7px;
	padding-right: 7px;

	&:last-child {
		border-bottom: none;
	}

	.lineItemMessaging {
		@apply tw-pt-3;
	}

	.availabilityMessages {
		@apply tw-font-bold;
	}

	@screen md {
		@apply tw-pb-10 tw-px-3;
		grid-template-columns: 2.5fr 9.5fr;
	}

}

.lineItemImageContainer {
	@apply tw-text-center;

	min-width: 140px;

	picture, img {
		max-width: 140px;
		max-height: 140px;
	}
}

.line-item-info-column {
	@apply tw-grid tw-gap-3 tw-content-start;

	@media print {
		@apply tw-gap-2;
	}
}

.line-item-title {
	&, & a, & button {
		@apply tw-font-semibold tw-relative tw-text-gray-100 tw-no-underline tw-text-left;

		font-size: 17px;
	}

	a {
		&:hover, &:focus {
			@apply tw-underline;
		}
	}

	@screen md {
		&, & a, & button {
			@apply tw-text-base tw-pr-10;
		}
	}
}
