.QuotesTable {
	width: 100%;
	@screen md {
		@apply tw-mb-5;
	}
	thead tr {
		border-bottom: 1px solid;
		@apply tw-border-brown;
	}
	th {
		@apply tw-font-bold tw-pb-2;
	}
	thead tr th {
		@apply tw-text-base tw-align-top;
	}
	td {
		@apply tw-align-top;
		@screen md {
			@apply tw-pt-3 tw-pb-12;
		}
	}
	tr {
		border-bottom: 1px solid;
		@apply tw-flex tw-flex-wrap tw-py-4;

		@screen md {
			@apply tw-border-gray-400 tw-table-row;
		}
	}
	tr:last-child,
	tr:nth-last-child(2) {
		@screen sm {
			border-bottom: 0px !important;
			td {
				@apply tw-pb-0;
			}
		}
	}

	.Date,
	.QuoteNumber,
	.Status {
		flex: 0 0 49%;
		@screen md {
			width: 12%;
		}
	}

	.PurchaseOrder,
	.ProjectName {
		width: 17%;
	}

	tr.LoadingRow {
		@apply tw-border-none;

		&.even td {
			@apply tw-bg-gray-500;
		}

		td {
			@apply tw-text-center tw-p-4 tw-h-14 tw-relative tw-bg-gray-600 tw-bg-opacity-40 tw-border-none tw-border-b-0 tw-overflow-hidden;



			&::after {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transform: translateX(-100%);
				background-image: linear-gradient(
				90deg,
				rgba(#fff, 0) 0,
				rgba(#fff, 0.2) 40%,
				rgba(#fff, 0.5) 60%,
				rgba(#fff, 0)
				);
				animation: shimmer 1.5s infinite;
				content: '';
			}

			@keyframes shimmer {
				100% {
				transform: translateX(100%);
				}
			}
		}


	}

	// .Date,
	// .OrderNumber,
	// .Total {
	// 	flex: 0 0 49%;
	// 	@screen md {
	// 		width: 200px;
	// 	}
	// }
	// .Items {
	// 	@screen md {
	// 		width: 150px;
	// 	}
	// }
	// .Total {
	// 	@screen md {
	// 		@apply tw-text-left;
	// 		// padding-right: 116px;
	// 	}
	// }
}

.infoSearchAndNav {
	@apply tw-grid tw-grid-cols-2 tw-w-full tw-gap-y-4;

	nav {
		@apply tw-justify-self-end;
	}

	form {
		@apply tw-mb-3;
	}

}

.QuotesTooltip {
	@apply tw-grid tw-grid-cols-4 tw-gap-4;
	width: 550px;
	.MoreItems {
		@apply tw-flex tw-items-center tw-justify-center;
	}
}

.SearchByOrderNumberBtn {
	@apply tw-font-normal tw-px-1;
	height: 27px;
}
