.summaryAndLineItems {
	@apply tw-grid tw-gap-4 tw-pb-5;

	grid-template-columns: 8fr 4fr;
}

.canceledMessage {
	@screen md {
		@apply tw-mt-7;
	}
}

$closeBtnSize: 32px;
.exitButtonWrapper {
	@apply tw-shrink-0 tw-relative tw-flex tw-justify-end tw-items-start;
	top: -10px;

	& > *:not(:last-child) {
		margin-right: 30px;

		&:after {
			@apply tw-absolute;
			content: "|";

			right: -29px;
			align-self: center;
			margin-right: 12px;
		}
	}
}

.undoButton {
	@apply tw-relative;

	padding-right: 39px !important;

	img {
		@apply tw-ml-2 tw-absolute tw-top-0;

	}
	&:after {
		margin-left: 51px;
	}
}

.exitButton, .undoButton {
	@apply tw-z-10 tw-relative tw-h-full tw-cursor-pointer tw-p-1 tw-text-gray-100 tw-no-underline;

	// For specificity
	&:focus,
	&:hover {
		@apply tw-shadow-none tw-underline;
		outline: 1px dotted theme("colors.gray.300");
		outline-offset: 8px;
	}
}

.exitButton {
	padding-right: $closeBtnSize + 15px;

	&::before,
	&::after {
		@apply tw-bg-gray-300 tw-absolute;
		top: 4px;
		content: "";
	}

	&::before {
		height: $closeBtnSize;
		transform: rotate(45deg) translate(4px, -11px);
		width: 1px;
		right: 24px;
	}

	&::after {
		height: 1px;
		transform: rotate(45deg) translate(4px, 11px);
		width: $closeBtnSize;
		right: -7px;
	}
}
