#react-magic-tray {
	--animSpeed: 350ms;

	:global .MagicModal {
		padding: 0;
	}

	.magic-modal-scroll-container {
		padding: 0 15px 25px;
		@screen md {
			padding: 0 30px 25px;
		}
	}

	:global .MagicModal-header {
		padding-top: 25px;
		background-color: white;
		position: sticky;
		top: 0px;
		@apply tw-z-10;
	}


	// sliding the modal in from left or right
	:global .slide-enter {
		&.left-aligned {
			.MagicModal {
				visibility: visible;
				top: 0;
				bottom: 0;
				position: fixed;
				left: -500px; // initially off screen
				transform: translateX(-100%);
			}
		}
		&.right-aligned {
			.MagicModal {
				visibility: visible;
				top: 0;
				bottom: 0;
				position: fixed;
				right: -500px; // initially off screen
				transform: translateX(100%);
			}
		}
	}

	:global .slide-enter.slide-enter-active {
		&.right-aligned {
			.MagicModal {
				right: 0;
			}
		}
		&.left-aligned {
			.MagicModal {
				left: 0;
			}
		}
		.MagicModal {
			top: 0;
			bottom: 0;
			outline: 0;
			z-index: 2010;
			position: fixed;
			overflow-y: auto;
			background-color: white;
			transition: transform var(--animSpeed) ease-in;
			transform: translateX(0);
			@screen sm {
				max-width: 100vw;
				transition: transform var(--animSpeed) ease-in 100ms;
			}
		}
	}

	:global .slide-enter-done {
		&.right-aligned {
			.MagicModal {
				right: 0;
			}
		}
		&.left-aligned {
			.MagicModal {
				left: 0;
			}
		}
		.MagicModal {
			top: 0;
			bottom: 0;
			outline: 0;
			z-index: 2010;
			position: fixed;
			overflow-y: auto;
			background-color: white;
			transform: translateX(0);
			@screen sm {
				max-width: 100vw;
			}
		}
	}

	:global .slide-exit.right-aligned .MagicModal {
		visibility: visible;
		top: 0;
		bottom: 0;
		position: fixed;
		right: 0;
		transform: translateX(0);
	}

	:global .slide-exit.left-aligned .MagicModal {
		visibility: visible;
		top: 0;
		bottom: 0;
		position: fixed;
		left: 0;
		transform: translateX(0);
	}


	:global .slide-exit.slide-exit-active {
		&.right-aligned {
			.MagicModal {
				right: 0;
				transform: translateX(100%);
			}
		}
		&.left-aligned {
			.MagicModal {
				left: 0;
				transform: translateX(-100%);
			}
		}
		.MagicModal {
			top: 0;
			height: 100%;
			outline: 0;
			z-index: 2010;
			position: fixed;
			overflow-y: auto;
			transition: transform var(--animSpeed) ease-out;
		}
	}


	// overlay background
	:global .slide-enter .magic-modal-overlay {
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 0;
	}
	:global .slide-enter.slide-enter-active .magic-modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 1;
		transition: opacity var(--animSpeed) ease-in;
		z-index: 1000;
	}
	:global .slide-enter-done .magic-modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 1;
		z-index: 1000;
	}

	:global .slide-exit.slide-exit-active .magic-modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: opacity var(--animSpeed) ease-out 200ms;
		z-index: 1000;
		@screen md {
			transition: opacity var(--animSpeed) ease-out;
		}
	}

	// inner shaddow to show when modals content is taller than screen
	.tray-shadow:after {
		background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
		bottom: 0;
		content: ' ';
		display: block;
		height: 10px;
		left: 0;
		opacity: .2;
		position: sticky;
		width: 100%;
		z-index: 2;
	}
}