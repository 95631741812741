.signIn {
	@apply tw-flex tw-flex-col tw-items-center tw-h-screen tw-justify-center;

	.logo {
		margin: 0 0 50px;
		width: 136px;

		&.loading {
			-webkit-animation: spin 4s linear infinite;
			-moz-animation: spin 4s linear infinite;
			animation: spin 4s linear infinite;
		}
	}

	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	/* CSS */
	.flipContainer {
		perspective: 1000px;
		width: 136px;
		height: 160px;
		margin-bottom: 50px;
		margin-left: -27px;
	}

	.flipper {
		width: 136px;
		height: 160px;
		transition: 0.6s;
		transform-style: preserve-3d;
		position: relative;
	}

	.front,
	.back {
		backface-visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
	}

	.front {
		z-index: 2;
		transform: rotateY(0deg);
	}

	.back {
		@apply tw-relative;
		width: 136px;
		height: 160px;
		transform: rotateY(180deg);
	}

	.flipper.loading {
		transform: rotateY(180deg);
	}

	.signInMessage {
		@apply tw-absolute tw-text-center tw-left-0 tw-right-0;
		z-index: 1000;
		top: 62%;
	}

	.engage-login {
		max-width: 320px;
	}
	.server-error {
		margin: 0 0 10px;
		* {
			// color: get-color(red01);
		}
	}
}
.signInForm {
	max-width: 335px;

	.input {
		width: 300px;
		max-width: 96vw;
	}
	pre {
		margin-left: -30px;
		// @media (max-width: 500px) {
		// 	margin: 5px 0 15px 0;
		// 	word-break: break-all;
		// 	max-width: 96vw;
		// }
		width: 300px;
		white-space: pre-wrap;
		// color: get-color(gray01);
	}
}

.signInButton {
	max-width: 300px;
	width: 300px;
}
