.customerInfoGrid {
	@apply tw-grid tw-gap-4 tw-pt-3 tw-pb-8;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@media print {
		@apply tw-hidden;
	}

	.column {
		@apply tw-grid tw-gap-y-6 tw-justify-stretch tw-content-start tw-w-full;
	}
}

.printGrid {
	@apply tw-hidden;
}

@media print {
	.printGrid {
		@apply tw-grid tw-gap-4 tw-pt-3 tw-pb-8;
		grid-template-columns: 1fr 1fr;

		.column {
			@apply tw-grid tw-gap-y-6 tw-justify-stretch tw-content-start tw-w-full;
		}
	}

}
