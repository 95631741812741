.spinnerContainer {
	@apply tw-relative;
	height: 500px;
}

.favoritesList {
	list-style: none !important;
}

.favoritesList li {
	position: relative;
	padding-left: 10px;
}

.favoritesList li::before {
	content: "";
	position: absolute;
	left: -20px;
	top: 50%;
	width: 20px;
	height: 13px;
	background-image: url("https://rnb.scene7.com/is/content/roomandboard/biPage21_checkmark");
	background-size: contain;
	background-repeat: no-repeat;
	transform: translateY(-50%);
}