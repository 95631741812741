.closeButtonContainer {
	@apply tw-relative tw-flex-shrink-0;
	width: 23px;
	height: 23px;
}
.closeButton {
	@apply tw-absolute tw-top-0 tw-right-0 tw-w-full tw-h-full tw-cursor-pointer;
	&::before,
	&::after {
		@apply tw-absolute tw-top-0 tw-left-0 tw-bg-gray-300;
		content: '';
	}
	&::before {
		@apply tw-w-px;
		height: 32px;
		transform: rotate(45deg) translate(4px, -11px);
	}
	&::after {
		@apply tw-h-px;
		width: 32px;
		transform: rotate(45deg) translate(4px, 11px);
	}
	&:focus {
		outline: 1px dotted theme('colors.gray.300');
		outline-offset: 8px;
	}
}