.close-btn-white-bkg {
    top: 15px !important;
    right: 15px !important;
    :global .MagicModal-closeBtn {
        @apply tw-bg-white tw-w-[40px] tw-h-[40px];
        &::before {
            top: 9px;
            left: 9px;
        }
        &::after {
            top: 9px;
            left: 9px;
        }
    }
}
