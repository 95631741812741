@use 'sass:map';

@media print {
	.location-INFO_COLUMN {
		@apply tw-hidden;
	}
}

.pricing-section {
	border-top: 1px solid theme("colors.gray.400");
	border-bottom: 1px solid theme("colors.gray.400");
	padding: 10px 0 9px;

	@media print {
		@apply tw-hidden;
	}
}

.pricing-table {
	@apply tw-w-full;

	th {
		@apply tw-text-xs tw-font-normal tw-text-left tw-align-top;

		height: 28px;

		&.price-col {
			width: 37.5%;
		}

		&.quantity-col {
			@apply tw-text-center;
			width: 25%;
		}

		&.subtotal-col {
			width: 37.5%;
		}

		@screen md {
			@apply tw-text-2xs;
		}
	}

	td {
		&.quantity-col {
			@apply tw-text-center;
		}
	}

	&.has-discount td {
		@apply tw-align-text-top;
	}
}

.quantitySelect {
	width: 65px;
	margin: 5px 0 0;
}
