.email-signup {
	@apply tw-flex tw-flex-nowrap tw-items-center;
	h2 {
		@apply tw-inline-flex tw-m-0 tw-p-0;
	}
	:global(.email-field-group) {
		margin: 0 3px 0 0;
	}
	.email-control {
		width: auto;
	}
	:global(.validation) {
		width: 100%;
		margin: 0;
	}
	.email-signup-form-disclaimer {
		@apply tw-text-gray;
		font-size: 13px;
	}

	&:global(.error) {
		padding: 0;

		:global(.error) {
			position: absolute;
			bottom: -22px;
		}
	}
}

.email-signup-modal {
	@apply tw-block;
	&.email-signup-modal-no-image {
		.email-signup-modal-content {
			padding: 0;
		}
		.email-signup-modal-heading-container {
			@apply tw-bg-navy;
			margin: 0 0 10px;
			padding: 20px 45px 5px 15px;
		}
		.email-signup-modal-heading {
			@apply tw-text-white;
		}
		.email-signup-modal-subheading {
			@apply tw-text-white;
		}
		.email-signup-modal-form-container {
			position: relative;
		}
		.email-signup-form {
			margin: 15px 15px 45px;
			:global(.email-field-group) {
				@apply tw-flex tw-flex-wrap;
				label {
					flex: 0 0 100%;
				}
				input {
					flex: 0 0 calc(100% - 75px);
					margin: 0 3px 0 0;
				}
			}
		}
	}

	.email-signup-modal-image {
		margin: 0 30px 0 0;
	}
	.email-signup-modal-content {
		flex: 1 1 100%;
		padding: 60px 75px 0 0;
	}

	.email-signup-modal-heading-container {
		margin: 0 0 10px;
	}

	.email-signup-modal-heading {
		margin: 0 0 8px !important;
	}

	.email-signup-form {
		:global(.email-field-group) {
			@apply tw-relative;
			input {
				width: 250px;
			}
		}
		:global(.validation) {
			bottom: -25px;
		}
	}
	.email-signup-form-disclaimer {
		@apply tw-text-gray-300;
		margin: 10px 0 20px;
		font-size: 14px;
	}
	.email-signup-success {
		margin: 20px;
	}
}

@screen md {
	.email-signup-modal {
		@apply tw-flex;

		&.email-signup-modal-no-image {
			.email-signup-modal-heading-container {
				padding: 25px 80px 15px 30px;
			}

			.email-signup-form {
				margin: 25px 30px 35px;

				:global(.email-field-group) {
					input {
						flex: 0 0 250px;
					}
				}
			}
		}

		.email-signup-form-disclaimer {
			font-size: 13px;
		}

		.email-signup-success {
			margin: 0;
		}
	}
}
