@use "sass:math";

$monthNavSize: 9.9px;

.monthNav {
	@apply tw-absolute tw-top-1/2 tw-left-1/2;
	width: $monthNavSize;
	height: $monthNavSize;
}
.monthNavDisabled,
.monthNav.monthNavDisabled,
.monthNavBtn[disabled] {
	@apply tw-border-gray-400;
}
.monthNavBtn {
	@apply tw-w-11 tw-h-7 tw-p-2 tw-border tw-border-solid tw-border-gray-300 tw-relative;
}
.monthNavPrev {
	@apply tw-border-b tw-border-l;
	transform: rotate(45deg) translate(-5px, -2px);
}
.monthNavNext {
	@apply tw-border-b tw-border-r;
	transform: rotate(-45deg) translate(-2px, -8px);
}
.calendar {
	@apply tw-w-full tw-border-collapse tw-table-fixed tw-mt-3;
	th {
		@apply tw-text-xs tw-font-normal tw-text-center tw-p-2 tw-select-text;
	}
	td {
		@apply tw-border-gray-400 tw-text-center tw-relative tw-overflow-hidden tw-p-0;
		height: inherit;
	}
}
// Borderize the calendar cells, and remove borders from empty cells.
.calendarBodyRow {
	.calendarBodyCol {
		@apply tw-border-t;
	}

	&.firstRow .calendarBodyCol {
		@apply tw-border-l;
		&.lastCol {
			@apply tw-border-r;
		}

		&.emptyCol {
			@apply tw-border-l-0 tw-border-t-0;
		}
	}

	&.middleRow .calendarBodyCol {
		@apply tw-border-l tw-border-r;
	}

	&.lastRow .calendarBodyCol {
		@apply tw-border-b;
		&.firstCol {
			@apply tw-border-l;
		}

		&, &.lastCol {
			@apply tw-border-r;
		}

		&.emptyCol {
			@apply tw-border-b-0 tw-border-r-0;
		}
	}
}
.dateCell {
	@apply tw-overflow-hidden;
	aspect-ratio: 49 / 25;
	&.unavailable {
		@apply tw-flex tw-flex-col tw-justify-center tw-bg-gray-500;
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100"><path d="M100 0 L0 100" stroke="%23ccc" stroke-width="2"/></svg>');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%, auto;
	}
}
.dateCellBtn {
	@apply tw-w-full tw-h-full tw-font-bold tw-relative;
	outline-offset: -2px !important;
	&:hover {
		@apply tw-border tw-border-dotted tw-border-gray-400;
	}
	&.selected {
		@apply tw-border-none tw-bg-gray tw-text-white;
	}
}

@screen md {
	.calendar {
		th {
			@apply tw-text-sm;
		}
	}
}