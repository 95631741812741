@use 'sass:map';

@import 'quote-print-settings';

@media print {
	.location-INFO_COLUMN {
		@apply tw-hidden;
	}
}

.pricing-section {
	border-top: 1px solid theme("colors.gray.400");
	border-bottom: 1px solid theme("colors.gray.400");
	padding: 10px 0 9px;

	@media print {
		@apply tw-hidden;
	}
}

.printPricing {
	@apply tw-hidden tw-text-gray-300;

	@media print {
		@apply tw-block;

		@each $printType, $config in $imagePrintVisibility {
			&.printType-#{$printType} {
				@if map-get($config, "details") == false {
					.printUnitPrice, .printQuantity, .printDeliveryMethod, .printSidemarking {
						@apply tw-hidden;
					}
				}

				@if map-get($config, "price") == false {
					.printUnitPrice {
						@apply tw-hidden;
					}
				}
			}
		}
	}
}

.pricing-table {
	@apply tw-w-full;

	th {
		@apply tw-text-xs tw-font-normal tw-text-left tw-align-top;

		height: 28px;

		&.price-col {
			width: 37.5%;
		}

		&.quantity-col {
			width: 25%;
		}

		&.subtotal-col {
			width: 37.5%;
		}

		@screen md {
			@apply tw-text-2xs;
		}
	}

	&.has-discount td {
		@apply tw-align-text-top;
	}
}

.quantity-select {
	width: 80px;
	margin: 5px 0 0;
}
