/** This is currently only used in storybook, we should apply this to the rest of formz **/
@media screen {
    .after-label {
        @apply tw-text-gray-300;
        margin: 0px 0 5px;
    }

    .disabled-field {
        opacity: 0.5;
    }

	input + .text-area-countdown {
		@apply tw-mt-1;
	}

	input:disabled + .text-area-countdown {
		opacity: 0.5;
	}

    textarea:disabled + .text-area-countdown {
        opacity: 0.5;
    }

    .optional-text {
        @apply tw-font-semibold;
    }
}
