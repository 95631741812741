// .Date,
// .QuoteNumber,
// .Status {
// 	flex: 0 0 49%;
// 	@screen md {
// 		width: 10%;
// 	}
// }

// .PurchaseOrder,
// .ProjectName {
// 	width: 18%;
// }

.expiredDate {
	@apply tw-text-2xs tw-text-gray-300;
}
