@import 'css/lib/tailwind-custom/arrow';

.icon {
	@apply tw-flex tw-items-center tw-justify-between tw-text-blue tw-underline;
	width: 37px;
	height: 20px;

	.arrow {
		@apply tw-relative;
		top: -2px;

		&:after {
			@include arrow(6px, theme('colors.gray.DEFAULT'), down, 1px);

		}
	}
}
