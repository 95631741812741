@media screen {
	.login-container {
		margin: 16px 0 60px;

		.spinner-container {
			min-height: 500px;
		}

		h2, h1 {
			@apply tw-text-gray tw-m-0 tw-p-0 tw-leading-normal tw-font-light;
			font-size: 28px;

			& + .form-intro {
				@apply tw-text-gray;
				margin: 8px 0 14px;
			}
		}

		.form-intro {
			font-size: 19px;
			line-height: 1.9rem;
		}

		.info-section {
			.form-intro {
				@apply tw-font-semibold tw-text-sm tw-mb-2;
			}
		}

		.password-container {
			:global(.inline-group) + * {
				flex-basis: 100%;
			}
		}

		ul {
			list-style: disc;
			margin: 6px 0 16px 18px;
			padding-left: 0;

			li {
				font-size: 15px;
				line-height: 31px;
			}
		}
	}

	@screen smOnly {
		.login-container {
			ul {
				list-style: disc;
				margin-left: 22px;
			}
		}
	}
}
