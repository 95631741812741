@use 'sass:map';

@import 'quote-print-settings';

.wasPrice {
	@media print {
		@apply tw-inline-block tw-ml-1;

		&.printType-COMPACT_VIEW, &.printType-PROFORMA_INVOICE {
			@apply tw-block;
		}
	}
}
