.summaryAndLineItems {
	@apply tw-grid tw-gap-4 tw-grid-cols-12 tw-border tw-border-gray-400;

	@media print {
		@apply tw-grid-cols-1 tw-border-none;

		border-bottom: solid 1px theme('colors.gray.400');

		.verticalDivider {
			@apply tw-hidden;
		}

		.lineItems {
			@apply tw-col-span-1;
		}
	}
}

.verticalDivider {
	@apply tw-hidden md:tw-block tw-col-span-1;
	&::before {
		@apply tw-bg-gray-400 tw-w-px tw-block tw-left-1/2 tw-relative;
		content: '';
		top: 1rem;
		height: calc(100% - 2rem);
	}
}

.lineItems {
	@apply tw-mt-4 tw-grid tw-gap-4;

	@screen md {
		@apply tw-col-span-7;
	}
}

.canceledMessage {
	@screen md {
		@apply tw-mt-7;
	}
}

.invoiceTitle {
	@apply tw-hidden;
}

@media print {
	.print-DEFAULT {
		@apply tw-block;
	}
	.print-NO_PRICING {
		@apply tw-block;
	}
	.print-NO_DISCOUNT {
		@apply tw-block;
	}
	.print-COMPACT_VIEW {
		@apply tw-block;
	}
	.print-PROFORMA_INVOICE {
		@apply tw-block;

		.quoteTitle {
			@apply tw-hidden;

		}
		.invoiceTitle {
			@apply tw-inline;
		}
	}
}
