.legend {
	@apply tw-border tw-border-gray-400 tw-w-6 tw-h-5 tw-relative tw-overflow-hidden;
	&.unavailable {
		@apply tw-bg-gray-500 tw-flex tw-flex-col tw-justify-center;
		&::before {
			@apply tw-absolute tw-w-full tw-border-l tw-border-solid tw-border-gray-400 tw-left-0 tw-top-0;
			@apply tw-origin-left;
			height: calc(100% + 35px);
			content: '';
			transform: translate(50%, -18px) rotate(50deg);
		}
	}
	&.available {
		@apply tw-border-none tw-bg-gray;
	}
}