@use 'sass:map';

@import 'quote-print-settings';

.businessBanner {
	@apply tw-grid tw-gap-x-3 tw-grid-flow-col tw-justify-start tw-px-5 tw-py-4 tw-bg-gray-600;

	@media print {
		@apply tw-flex tw-flex-wrap;
		print-color-adjust: exact;
		-webkit-print-color-adjust: exact;

		@each $printType, $config in $imagePrintVisibility {
			&.printType-#{$printType} {
				@if map-get($config, "discountBanner") == false {
					@apply tw-hidden;
				}
			}
		}
	}

	& > .businessBannerTitle {
		margin-right: 5px;
	}

	& > *:not(:last-child):not(.businessBannerTitle) {
		&:after {
			content: "|";
			align-self: center;
			margin-left: 12px;
		}
	}
}
