.tagContainer {
	@apply tw-inline-flex tw-items-center tw-gap-x-0.5 tw-rounded-md tw-px-2 tw-py-1 tw-text-xs;
	.tagButton {
		@apply tw-relative tw--mr-1 tw-h-3.5 tw-w-3.5 tw-rounded-sm;
	}
	.tagCloseIcon {
		@apply tw-h-3.5 tw-w-3.5;
	}
	&.included {
		background: rgb(220, 252, 231);
		color: rgb(21, 128, 61);
		.tagButton:hover {
			background: rgba(22, 163, 74, 0.2);
		}
		.tagCloseIcon {
			stroke: rgba(22, 101, 52, 0.5);
			&:hover {
				stroke: rgba(22, 101, 52, 0.75);
			}
		}
	}
	&.excluded {
		background: rgb(254,226,226);
		color: rgb(185,28,28);
		.tagButton:hover {
			background: rgba(220,38,38,0.2);
		}
		.tagCloseIcon {
			stroke: rgba(185,28,28,0.5);
			&:hover {
				stroke: rgba(185,28,28,0.75);
			}
		}
	}
}
