/// Creates a CSS arrow, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the arrow to an existing element.
///
/// @param {Number} $arrow-size - Width of the arrow.
/// @param {Color} $arrow-color - Color of the arrow.
/// @param {Keyword} $arrow-direction - Direction the arrow points. Can be `up`, `right`, `down`, or `left`.
@mixin arrow(
	$arrow-size,
	$arrow-color,
	$arrow-direction,
	$arrow-stroke-width,
) {
	display: inline-block;
	border-bottom: 1px solid $arrow-color;
	border-right: 1px solid $arrow-color;
	border-left: none;
	border-top: none;
	border-width: $arrow-stroke-width;
	width: $arrow-size;
	height: $arrow-size;

	content: '';

	@if ($arrow-direction == down) {
		transform: rotate(45deg);
	}
	@if ($arrow-direction == up) {
		transform: rotate(-135deg);
	}
	@if ($arrow-direction == right) {
		transform: rotate(-45deg);
	}
	@if ($arrow-direction == left) {
		transform: rotate(135deg);
	}
}
