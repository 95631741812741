.Wizard {
	margin: auto;
	width: auto;
	height: 15px;
}
.WizardIndicator {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	width: 100%;
	position: relative;
	z-index: 0;
	&::before {
		border: 1px solid theme('colors.gray.300');
		border-top: none;
		border-right: none;
		border-left: none;
		position: absolute;
		width: 100%;
		content: ' ';
		top: calc(50% - 1px);
		z-index: -5;
	}
}
.WizardStep {
	border: 1px solid theme('colors.gray.300');
	display: flex;
	justify-content: center;
	align-items: center;
	background: theme('colors.white.DEFAULT');
	width: 15px;
	height: 15px;
	border-radius: 50%;
	list-style: none;
}
.WizardStepInside {
	background: transparent;
	border-radius: 50%;
	width: 9px;
	height: 9px;
	display: block;
	&.isActive,
	&.isComplete {
		background: theme('colors.gray.300');
	}
}
.Wizard.WizardVertical {
	width: 15px;
	height: auto;
	.WizardIndicator {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: auto;
		height: 100%;
		&::before {
			border: 1px solid theme('colors.gray.300');
			border-top: none;
			border-right: none;
			border-bottom: none;
			width: auto;
			height: 100%;
			top: 0;
			left: 7.5px;
		}
	}
}