.printSubHeader {
	@apply tw-gap-4 tw-hidden;

	@media print {
		@apply tw-grid;
		grid-template-columns: 1fr 1fr;
	}

	.column {
		@apply tw-grid tw-gap-y-1 tw-justify-stretch tw-content-start tw-w-full;
	}
}
